import React from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import { ImQuotesRight } from 'react-icons/im'
import { AiFillStar } from 'react-icons/ai'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentContainer from '@solid-ui-components/ContentContainer'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  avatar: {
    width: 150,
    bg: `omegaLighter`,
    borderTopColor: `omegaLighter`,
    borderTopWidth: `xl`,
    borderTopStyle: `solid`,
    borderRadius: `lg`,
    boxSizing: `content-box`
  },
  content: {
    flexBasis: `1/3`, 
    flexGrow: 1,
    mt: [4, 4, 4, 0],
    mb: [4, 4, 4, 4],
    ml: [0, 0, 0, 2]
  },
  lineBreak: {
    height: `1px`, 
    backgroundColor: `omegaLighter`, 
    border: `none`
  }
}

const TestimonialsBlock02 = ({ content: { text, collection } }) => (
  <Container>
    <Box sx={{ textAlign: `center` }}>
      <ContentText content={text} />
    </Box>
    <Flex sx={{ mx: -3, flexWrap: `wrap` }}>
      {collection?.map(({ container, avatar, text }, index) => (
        <Box
          key={`item-${index}`}
          sx={{ flexBasis: [`1`], flexGrow: 1, p: 3 }}
        >
            <Flex
              sx={{
                alignItems: `flex-start`,
                flexDirection: [`column`, null, null, `row`]
              }}
            >
              <ContentContainer
                content={container}
                variant='cards.paper'
                sx={{
                  flexBasis: `1/3`,
                  textAlign: `center`,
                  position: `relative`,
                  width: `full`
                }}
              >
                <ImQuotesRight
                  css={css({
                    size: `icon.lg`,
                    color: `omegaLighter`,
                    position: `absolute`,
                    bottom: `-12%`,
                    left: `50%`,
                    transform: `translate(-50%, 0)`
                  })}
                />
                <ContentText content={text?.slice(0, 2)} />
                <Box mt='3'>
                  {Array.from({ length: 5 }, (_, i) => (
                    <AiFillStar
                      key={`item-${i}`}
                      css={css({ color: `alphaLight` })}
                    />
                  ))}
                </Box>
              </ContentContainer>
              <Box
                sx={{
                  ml: [0, null, null, -3],
                  mr: [0, null, null, 3],
                  mt: [-4, null, null, 0],
                  mb: [3, null, null, 0]
                }}
              >
                <ContentImages
                  content={{ images: [avatar] }}
                  sx={styles.avatar}
                />
              </Box>
              <Box sx={styles.content}>
                <ContentText content={text?.slice(2)} />
              </Box>
            </Flex>
            {index < collection.length - 1 && <hr sx={styles.lineBreak}/>}
        </Box>
      ))}
    </Flex>
  </Container>
)

export default WithDefaultContent(TestimonialsBlock02)
