import React from 'react'
import { Box } from 'theme-ui'

const FlexContent = ({ children, reverse }) => (
  <Box
    sx={{
      flexBasis: `1/2`,
      zIndex: [null, 1],
      mx: [null, null, null, 4]
    }}
  >
    {children}
  </Box>
)

export default FlexContent
