import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Companies from '@solid-ui-blocks/Companies/Block01'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import WhyChooseUs from '@solid-ui-blocks/Features/Block06'
import CallToAction from '@solid-ui-blocks/CallToAction/Block02'
import Feature from '@solid-ui-blocks/Features/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Testimonials from '@solid-ui-blocks/Testimonials/Block02'
import Faq from '@solid-ui-blocks/Faq/Block02'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Polished Concrete Melbourne – Concrete Polishers' />

      {/* Blocks */}
      <Header content={content['header']} />

      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      <Divider space='3' />

      <WhyChooseUs content={content['why-choose-us']} />
      <Divider space='3' />

      <CallToAction content={content['cta']} />
      <Divider space='3' />
      
      <FeatureTwo content={content['intro']} />
      <Divider space='3' />

      <FeatureOne content={content['polished-concrete']} reverse/>
      <Divider space='3' />
      
      <FeatureOne content={content['concrete-grinding']} />
      <Divider space='3' />

      <FeatureOne content={content['grind-and-seal']} reverse/>
      <Divider space='3' />

      <FeatureOne content={content['epoxy-floors']} />
      <Divider space='3' />

      <Companies content={content['companies']} />
      <Divider space='3' />
      
      <Testimonials content={content['testimonials']} />
      <Divider space='3' />

      <Feature content={content['flooring-partner-pitch']} />
      <Divider space='3' />

      
      <Faq content={content['faq']} identifier='faq' />
      <Divider space='3' />

      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepageSiteBlockContent {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
