/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

const styles = {
  heroContainer: {
    bg: `#F6F7FA`,
    pt: [6, 6, 6, 6]
  },
  buildBrandContainer: {
    position: `relative`,
    '::before': {
      content: `" "`,
      size: `full`,
      position: `absolute`,
      right: 0,
      top: `13%`,
      zIndex: -1,
      opacity: 0.3,
      backgroundSize: `40%`
    }
  },
  getStartedContainer: {
    position: `relative`,
    '::before': {
      content: `" "`,
      size: `full`,
      position: `absolute`,
      top: [`25%`, 0],
      right: 6,
      zIndex: -1,
      backgroundSize: `55%`
    }
  }
}

export default styles;